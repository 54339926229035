<!-- 专项讲解-- 新增 -->
<template>
	<edit-template style="background: rgba(247, 247, 247, 1);padding-right: 40rem" @confirm="onSubmit" @cancel="$confirm('取消新增?', '提示', { type: 'warning' }).then(() => $router.back())" :cancelRemind="false" confirmText="提交" cancelText="取消">
		
    <div class="title">
      <div class="title-bottom" style="padding-top: 24rem">
        <div class="flex" style="font-weight: bold">
          <div>
            <span class="span1">讲解教师</span>
            <span class="span2">{{ dataCont.clerk_name }}</span>
          </div>
          <div style="margin-left: 100rem">
            <span class="span1">讲解科目</span>
            <span class="span2">{{ dataCont.subject_name }}</span>
          </div>
        </div>
      </div>
    </div>

    <el-form ref="form" :model="formData" label-width="122rem" :rules="rules">
      <div class="title">
        <div class="title-title">
          <div style="margin-top: 19rem;margin-left: 24rem">讲解接收人</div>
        </div>
        <div class="title-bottom">
          <el-form-item label="选择校区" prop="school_id">
            <el-select :popper-append-to-body="false" v-model="formData.school_id" @change="schoolChange">
              <el-option v-for="item in schoolOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option></el-select>
          </el-form-item>
          <el-form-item label="选择年级" prop="grade_id">
            <el-select :popper-append-to-body="false" v-model="formData.grade_id" @change="gradeChange">
              <el-option v-for="item in gradeOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option></el-select>
          </el-form-item>
          <el-form-item label="选择班级" prop="teaching_ids">
            <el-button :disabled="!formData.grade_id" @click="classDialogShow = true">点击选择</el-button>
            <div>
              <el-tag v-for="(item, index) in classTagList" :key="item.id" @close="onClassTagClose(item.id, index)" style="margin-right: 10rem" closable="">
                {{ item.name }}
              </el-tag>
            </div>
            <el-select style="display: none" v-model="formData.teaching_ids" multiple="" collapse-tags="">
          </el-select></el-form-item>
          <el-form-item label="选择讲解接收人" prop="student_ids">
            <template v-if="formData.teaching_ids.length === 1 || formData.teaching_ids.length === 0">
              <el-button :disabled="formData.teaching_ids.length === 0" @click="studentDialogShow = true">点击选择
              </el-button>
              <div v-if="formData.teaching_ids.length === 1">
                <el-tag v-for="(item, index) in studentTagList" :key="item.id" @close="onStudentTagClose(item.id, index)" style="margin-right: 10rem" closable="">
                  {{ item.student_name }}
                </el-tag>
              </div>
              <el-select style="display: none" v-model="formData.student_ids" multiple="">
            </el-select></template>
            <template v-else="">
              <el-select :popper-append-to-body="false" v-model="studentSelectDefault">
                <el-option label="全部学生" value="0">
              </el-option></el-select>
              <el-input style="display: none" v-model="formData.student_ids">
            </el-input></template>
          </el-form-item>
        </div>
      </div>

      <div class="title">
        <div class="title-title">
          <div style="margin-top: 19rem;margin-left: 24rem">讲解内容</div>
        </div>
        <div class="title-bottom">
          <el-form-item label="讲解标题" label-width="82rem" prop="title">
            <el-input placeholder="请输入50字以内的标题" v-model="formData.title" maxlength="50" @input="checkComplete"></el-input>
          </el-form-item>
          <el-form-item label="讲解内容" label-width="82rem" prop="content">
            <div style="border: 1rem solid #ccc;margin-top: 10rem">
              <toolbar :defaultconfig="toolbarConfig" :editor="editor" :mode="mode" style="border-bottom: 1rem solid #ccc">
              <editor v-model="richContent" :defaultconfig="editorConfig" :mode="mode" @onchange="richChange" style="height: 500rem; overflow-y: hidden;" @oncreated="onCreated">
            </editor></toolbar></div>
            <el-input v-model="formData.content" style="display: none"></el-input>
          </el-form-item>
        </div>
      </div>

      <div class="title">
        <div class="title-title">
          <div style="margin-top: 19rem;margin-left: 24rem"><span class="re">关联知识点</span></div>
        </div>
        <div class="title-bottom">
          <div class="knowledge" v-for="(item, index) in knowledge" :key="index">
            <div class="knowledge_box">
              <el-input v-model="item.category" maxlength="100" placeholder="请输入知识点分类" @input="knowChange"></el-input>
              <el-input v-model="item.content" maxlength="100" placeholder="请输入知识点内容" @input="knowChange"></el-input>
            </div>
            <!-- 关联知识点新增删除题目按钮 -->
            <div class="add_knowledge">
              <el-button v-if="knowledge.length > 1" icon="el-icon-delete" circle="" @click="deleteKnowledge(index)"></el-button>
              <el-button icon="el-icon-plus" circle="" @click="addKnowledge" v-if="knowledge.length - 1 == index &amp;&amp; knowledge.length < 10"></el-button>
            </div>
          </div>
          <el-form-item label-width="0" prop="knowledge">
            <el-select style="display: none" v-model="formData.knowledge" multiple="" collapse-tags="">
          </el-select></el-form-item>
        </div>
      </div>
    </el-form>

    

    <!-- 班级穿梭框 -->
    <el-dialog :visible.sync="classDialogShow" width="60%" custom-class="transfer" @close="onClassDialogClose">
      <div>
        <el-transfer filterable="" :filter-method="classFilterMethod" filter-placeholder="请输入" v-model="classList" :titles="['待选区', '已选区']" :props="{
              key: 'id',
              label: 'name'
            }" :data="classOptions">
        </el-transfer>
      </div>
      <!--      <t-result v-if="!historyList.length" :type="'empty'"></t-result>-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClassDialogClose">取 消</el-button>
        <el-button type="primary" @click="onClassHandle">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 学生穿梭框 -->
    <el-dialog :visible.sync="studentDialogShow" width="60%" custom-class="transfer" @close="onStudentDialogClose">
      <div>
        <el-transfer filterable="" :filter-method="studentFilterMethod" filter-placeholder="请输入" v-model="studentList" :titles="['待选区', '已选区']" :props="{
              key: 'id',
              label: 'student_name'
            }" :data="studentsOptions">
        </el-transfer>
      </div>
      <!--      <t-result v-if="!historyList.length" :type="'empty'"></t-result>-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="onStudentDialogClose">取 消</el-button>
        <el-button type="primary" @click="onStudentHandle">确 定</el-button>
      </span>
    </el-dialog>

  
</edit-template>
</template>

<script>
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import {Message} from "element-ui";

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  components: {Editor, Toolbar},
  data() {
    return {
      OSS_URL: process.env["VUE_APP_URL_OSS"],
      studentDialogShow: false,
      classDialogShow: false,
      dataCont: '',
      richContent: '',
      toolbarConfig: {
        excludeKeys: [
          'fullScreen',
          'insertVideo'
        ],
      },
      editor: null,
      mode: 'simple', // or 'default',
      editorConfig: {
        placeholder: '请输入内容...',
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          // 图片上传
          uploadImage: {
            server: process.env.VUE_APP_URL_ONE + '/api/upload/upload',
            fieldName: 'upload',
            // 单个文件的最大体积限制，默认为 2M
            maxFileSize: 5 * 1024 * 1024, // 5M
            // 最多可上传几个文件，默认为 100
            // maxNumberOfFiles: 1,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ['image/*'],
            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
            meta: {},
            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: false,

            // 自定义增加 http  header
            headers: {
              authorization: 'Bearer ' + sessionStorage.getItem('TOKEN')
              // Accept: 'text/x-json',
              // otherKey: 'xxx'
            },

            //自定义上传方法
            customUpload: this.uploadImageMe,

            // 跨域是否传递 cookie ，默认为 false
            withCredentials: false,

            // 超时时间，默认为 10 秒
            timeout: 10 * 1000, //10 秒

            // 上传前
            // onBeforeUpload(files) {
            //   Message({
            //     message: '图片正在上传中,请耐心等待',
            //     duration: 0,
            //     customClass: 'uploadTip',
            //     iconClass: 'el-icon-loading',
            //     showClose: true
            //   });
            //   return files;
            // },

            // 自定义插入图片
            customInsert(res, insertFn) {
              // 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
              // 先关闭等待的Message
              // Message.closeAll();
              if (res.status === 0) {
                Message.success({
                  message: '上传成功'
                });
                insertFn(res.data.file_addr, '', res.data.file_addr);
              } else {
                Message.error({
                  message: res.msg
                });
              }
            },

            // 单个文件上传成功之后
            onSuccess(file, res) {
              // console.log(`${ file.name } 上传成功`, res);
            },

            // 单个文件上传失败
            onFailed(file, res) {
              Message.error('上传失败请重试')
            },

            // 上传进度的回调函数
            onProgress(progress) {
              // progress 是 0-100 的数字
            },

            // 上传错误，或者触发 timeout 超时
            onError(file, err, res) {
              if (file.size / 1024 / 1024 > 5) Message.error('上传失败,上传文件过大')
              else Message.error('上传失败请重试')
            }
          },
        }
      },

      schoolOptions: [],
      gradeOptions: [],
      classOptions: [],
      peopleOptions: [],
      studentsOptions: [],
      studentsOptions_all: [],
      formData: {
        school_id: '',
        grade_id: '',
        teaching_ids: [],
        student_ids: [],
        title: '',
        content: '',
        knowledge: [],
      },
      classList: [],
      classTagList: [],
      studentSelectDefault: '0',  //当班级多选时，学生下拉框的默认值
      studentList: [],  //中间值，用于渲染穿梭框右侧的值
      studentTagList: [],  //渲染tag的值
      knowledge: [
        {
          category: '',
          content: '',
        }
      ],
      rules: {
        school_id: [{required: true, message: '请选择校区', trigger: 'change'}],
        grade_id: [{required: true, message: '请选择年级', trigger: 'change'}],
        teaching_ids: [{required: true, message: '请选择班级', trigger: 'change'}],
        student_ids: [{required: true, message: '请选择讲解接收人', trigger: 'change'}],
        title: [{required: true, message: '请输入讲解标题', trigger: 'change'}],
        content: [{required: true, message: '请输入讲解内容', trigger: 'change'}],
        knowledge: [{required: true, message: '请输入关联知识点', trigger: 'change'}],
      },

      submitStatus: true
    }
  },

  created() {
    this.getInfo()
    this.getSchool()
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },

  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },

    richChange() {
      this.formData.content = this.richContent.replace(/<p>/g, '')
          .replace(/<\/p>/g, '')
          .replace(/<br>/g, '')
          .replace(/&nbsp;/g, '').trim();
      this.checkComplete()
    },

    deleteKnowledge(index) {
      this.knowledge.splice(index, 1)
      this.knowChange()
    },

    addKnowledge() {
      this.knowledge.push({
        category: '',
        content: '',
      })
    },

    getInfo() {
      this.$_axios2('api/question-group/student/teacher-detail').then(res => {
        this.dataCont = res.data.data
      })
    },

    getSchool() {
      this.$_axios2.get('api/common/school', {
        params: {
          level: 2,
          ispeiyou: 1
        }
      }).then(res => {
        let data = res.data.data;
        this.schoolOptions = data.school
      })
    },

    getGrade() {
      this.formData.grade_id = ''
      this.$_axios2.get('api/common/school', {
        params: {
          school_id: this.formData.school_id,
          level: 2,
          ispeiyou: 1
        }
      }).then(res => {
        let data = res.data.data;
        this.gradeOptions = data.grade
      })
    },

    getClass() {
      this.$_axios2.get('api/common/school', {
        params: {
          school_id: this.formData.school_id,
          grade_id: this.formData.grade_id,
          level: 2,
          ispeiyou: 1
        }
      }).then(res => {
        let data = res.data.data;
        this.classOptions = data.teaching_data
      })
    },

    getStudent() {
      let params = {
        teaching_ids: this.formData.teaching_ids.join(',')
      }
      this.$_axios2('api/question-group/student/teaching-student-list', {params}).then(res => {
        this.studentsOptions = res.data.data.student[0]
        this.studentsOptions_all = res.data.data
      })
    },

    schoolChange() {
      this.gradeReset()
      this.classReset()
      this.studentReset()
      this.getGrade()
    },

    gradeChange() {
      this.classReset()
      this.studentReset()
      this.getClass()
    },

    gradeReset(){
      this.formData.grade_id = ''
      this.gradeOptions = []
    },

    classReset(){
      this.formData.teaching_ids = []
      this.classList = []
      this.classTagList = []
    },

    studentReset(){
      this.studentsOptions = []
      this.formData.student_ids = []
      this.studentList = []
      this.studentTagList = []
    },

    classFilterMethod(query, item) {
      return item.name.indexOf(query) > -1;
    },

    onClassTagClose(id, index) {
      this.classTagList.splice(index, 1)
      let arr = this.formData.teaching_ids.filter(item => item !== id)
      this.classList = arr
      this.$set(this.formData, 'teaching_ids', arr)
      this.studentList = []
      this.studentTagList = []
      this.formData.student_ids = this.classTagList.length > 1 ? '0' : []
      if (this.classTagList.length === 1) {
        this.getStudent()
      }
      this.checkComplete()
    },

    onClassDialogClose() {
      this.classDialogShow = false
      setTimeout(() => {
        this.classList = this.formData.teaching_ids
      }, 200)
    },

    onClassHandle() {
      this.classDialogShow = false
      this.formData.teaching_ids = this.classList
      this.classTagList = this.classOptions.filter(item => this.formData.teaching_ids.includes(item.id))
      this.studentList = []
      this.studentTagList = []
      this.formData.student_ids = this.classTagList.length > 1 ? '0' : []
      if (this.classTagList.length >= 1) {
        this.getStudent()
      }
      this.checkComplete()
    },

    studentFilterMethod(query, item) {
      return item.student_name.indexOf(query) > -1;
    },

    onStudentTagClose(id, index) {
      this.studentTagList.splice(index, 1)
      let arr = this.formData.student_ids.filter(item => item !== id)
      this.studentList = arr
      this.$set(this.formData, 'student_ids', arr)
      this.checkComplete()
    },

    onStudentDialogClose() {
      this.studentDialogShow = false
      setTimeout(() => {
        this.studentList = this.formData.student_ids
      }, 200)
    },

    onStudentHandle() {
      this.studentDialogShow = false
      this.formData.student_ids = this.studentList
      this.studentTagList = this.studentsOptions.filter(item => this.formData.student_ids.includes(item.id))
      this.checkComplete()
    },

    knowChange() {
      this.formData.knowledge = this.knowledge.filter(item => item.category.trim() || item.content.trim());
      this.checkComplete()
    },

    checkComplete() {
      let data = JSON.parse(JSON.stringify(this.formData))
      let boo = false;
      for (let i in data) {
        if (data[i] === '' || data[i].length === 0) {
          boo = true;
          break;
        }
      }
      this.submitStatus = boo
    },

    uploadImageMe(file, insertFn) {
      const loading = this.$loading({
        lock: true,
        text: '上传中',
        customClass: 'img-upload-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$uploadToOSS_1(file, 1).then(res => {
        insertFn(res, file.name, res)
      }).finally(() => {
        loading.close()
      })
    },

    onSubmit() {
      this.$refs.form.validate((res, e, p) => {
        if (res) {
          let teaching_student = {};
          if (this.formData.teaching_ids.length === 1) {
            teaching_student[this.formData.teaching_ids[0]] = this.formData.student_ids
          } else {
            this.studentsOptions_all.teaching.forEach((item, index) => {
              teaching_student[item.id] = this.studentsOptions_all.student[index].map(item => item.id)
            })
          }
          let params = {
            school_id: this.formData.school_id,
            grade_id: this.formData.grade_id,
            title: this.formData.title,
            content: this.richContent,
            knowledge: this.formData.knowledge,
            subject_id: this.dataCont.subject_id,
            teaching_student,
          }
          this.$_axios2.post('api/question-group/student/gen-question-group', params).then(res => {
            if (res.data.status === 0) {
              this.$message({
                type: 'success',
                message: '提交成功',
                onClose: () => {
                  this.$store.commit("setPage", 1);
                  this.$router.replace('./details?id=' + res.data.data.base.id);
                }
              })
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-transfer-panel__body {
  height: 350rem !important;
}

::v-deep .el-transfer-panel__list.is-filterable {
  height: 294rem !important;
}

.title-title {
  font-size: 18rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 60rem;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1rem solid #F7F7F7;
  border-radius: 10rem 10rem 0 0;
  background: #FFF;
  box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);
}

.title-bottom {
  box-sizing: border-box;
  margin-bottom: 24rem;
  //padding-top: 28rem;
  padding-bottom: 24rem;
  padding-left: 32rem;
  padding-right: 20rem;
  border-radius: 0 0 10rem 10rem;
  background: #FFF;
}

.body {
  margin: 24rem 0;

  & .body-title {
    font-size: 18rem;
    font-weight: bold;
    box-sizing: border-box;
    height: 78rem;
    padding-top: 19rem;
    padding-left: 24rem;
    color: rgba(0, 0, 0, 0.8);
    border-bottom: 1rem solid #F7F7F7;
    border-radius: 10rem 10rem 0 0;
    background: #FFFFFF;
    box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);
  }

  & .body-bottom {
    box-sizing: border-box;
    min-height: 148rem;
    padding: 0 24rem 24rem;
    border-radius: 0 0 10rem 10rem;
    background: #FFFFFF;
    box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);

    & .body-bottom-label {
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
      font-size: 14rem;
      font-weight: 400;
      width: 96rem;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}

.el-table__body .cell {
  white-space: normal;
  word-wrap: break-word;
}

.bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 220rem;
  height: 60rem;
}

.box-1 {
  width: 25%;
}

.box-2 {
  width: 33.3%;
}

.box-3 {
  width: 50%;
}

.box {
  font-size: 14rem;
  color: rgba(0, 0, 0, .6);
  margin-top: 24rem;

  span {
    margin-right: 30rem;
    color: rgba(0, 0, 0, .8);
  }
}

.knowledge {
  padding-top: 10rem;
  display: flex;
  align-items: center;

  .knowledge_box {
    display: flex;
    padding-top: 12rem;
    padding-left: 20rem;
    height: 60rem;
    background-color: #f4f4f4;

    .el-input {
      margin-right: 20rem;
      width: 250rem;

      &:last-child {
        width: 400rem;
      }
    }
  }

  .add_knowledge {
    display: flex;
    padding-left: 40rem;
  }
}

.span3 {
  display: inline-block;
  padding: 6rem 10rem;
  font-size: 14rem;
  border-radius: 6rem;
  background: #f7f7f7;
  line-height: 24rem;
  margin-right: 12rem;
  margin-top: 12rem;
}

.rich-box {
  background: #f7f7f7;
  padding: 12rem;
  border-radius: 6rem;
}

.span1 {
  font-size: 14rem;
  color: rgba(0, 0, 0, .6);
  margin-right: 20rem;
}

.span2 {
  font-size: 14rem;
  color: rgba(0, 0, 0, .8);
}

.describe-box {
  background: #f7f7f7;
  padding: 12rem;
  border-radius: 6rem;
  font-size: 14rem;
  line-height: 26rem;
}

.re {
  position: relative;

  &:before {
    content: "*";
    color: #f53f3f;
    margin-right: 4rem;
  }
}
</style>
